<template>
  <div>
    <MainCard
      v-if="!isLoadingTable"
    >
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :items="violations"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :search="search"
          :no-add-button="noAddButton"
          :is-loading="isLoadingData"
          title="Mute/Suspend"
          subtitle="Daftar siswa yang di mute/suspend di dalam forum"
          add-btn="Tambah Data"
          @add-item="showFormAdd"
          @edit-item="edit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
    >
      <template
        v-slot:header
      >
        Edit Mute/Suspend
      </template>
      <template v-slot:body>
        <div class="text-center">
          <p class="d-block mb-8">
            Ubah Status '{{ violation.user.name }}'?
          </p>
          <div>
            <template>
              <v-radio-group
                v-model="violation.violation_type"
                class="mt-0"
                hide-details
              >
                <div class="d-flex justify-center align-center demo-space-x">
                  <v-radio
                    v-for="value in valueRadio"
                    :key="value.id"
                    :label="value.status"
                    :value="value.status"
                  ></v-radio>
                </div>
              </v-radio-group>
            </template>
          </div>
          <div class="mt-6">
            <v-text-field
              v-model="violation.reason"
              label="Alasan"
              outlined
              dense
            >
            </v-text-field>
          </div>
          <div>
            <v-menu
              v-model="deadline"
              :close-on-content-click="false"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="violation.end_date"
                  label="Jangka Waktu"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="violation.end_date"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="deadline = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="updateViolation()"
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDialog = false"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
    >
      <template v-slot:header>
        Hapus Mute/Suspend
      </template>
      <template v-slot:body>
        Apakah Anda yakin ingin menghapus dari daftar Mute/Suspend?
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="deleteViolation()"
        >
          Ya
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="confirmDialog = false"
        >
          Batal
        </v-btn>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiArrowLeft, mdiChevronDown, mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'MuteSuspend',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    SkeletonLoaderTable,
    ConfirmDialog,
  },
  data() {
    return {
      valueRadio: [
        {
          index: 1,
          status: 'active',
        },
        {
          index: 2,
          status: 'muted',
        },
        {
          index: 3,
          status: 'banned',
        },
      ],
      deadline: false,
      selectedRadio: '',
      search: '',
      page: 1,
      totalItems: 10,
      totalPages: 0,
      dialog: null,
      width: 600,
      modalDialog: false,
      confirmDialog: false,
      imageOverlay: false,
      noAddButton: true,
      violation: {
        user: {
          name: '',
        },
      },
      violations: [],
      filterViolation: '',
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      overlay: false,
      zIndex: 10,
      icons: {
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiArrowLeft,
        mdiChevronDown,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Siswa', value: 'user.name' },
        { text: 'Status', value: 'status' },
        { text: 'Jangka Waktu', value: 'end_date' },
        { text: 'Alasan', value: 'reason' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  async mounted() {
    this.isLoadingTable = true
    await this.getSchoolUuid()
    await this.getViolation()
    await this.getModerator()
    this.isLoadingTable = false
  },
  methods: {
    async getViolation() {
      this.isLoadingData = true
      await this.$services.ApiServices.listParams('violationforum', this.school_uuid, {
        page: this.page,
        search: this.filterViolation,
      }).then(
        async ({ data }) => {
          this.violations = await data.data.map((violation, index) => ({
            ...violation,
            index: index + 1,
            status: violation.violation_type,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    async getModerator() {
      await this.$services.ApiServices.get('employee', this.user_uuid).then(
        ({ data }) => {
          this.moderator = data.data
        },
        err => console.error(err),
      )
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },
    showFormAdd() {
      this.modalDialog = true
    },
    async edit(uuid) {
      this.violation = {
        user: {
          name: '',
        },
      }
      await this.$services.ApiServices.get('violationforum', uuid).then(
        async ({ data }) => {
          this.violation = data.data
        },
        err => console.error(err),
      )
      this.dialog = 'edit'
      this.modalDialog = true
    },
    async updateViolation() {
      this.violation.type = this.violation.violation_type
      this.isLoadingButton = true
      await this.$services.ApiServices.update('violationforum', this.violation, this.violation.uuid).then(
        ({ data }) => {
          this.forum = data.data
          this.showSnackbar({
            text: 'Update violation successfully',
            color: 'success',
          })
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
          this.violation = {}
        },
      )
      this.modalDialog = false
      await this.getViolation()
    },
    async deleteViolation() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('violationforum', this.violation.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Delete violation successfully',
            color: 'error',
          })
          this.isLoadingButton = false
          this.violation = {}
        },
        err => {
          this.isLoadingButton = false
          this.violation = {}
        },
      )

      this.confirmDialog = false
      await this.getViolation()
    },
    async searchHandler(data) {
      this.filterViolation = data
      this.page = 1
      await this.getViolation()
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.violation.uuid = uuid
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0 !important;
}
</style>

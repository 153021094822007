<template>
  <div>
    <MainCard
      v-if="!isLoadingTable"
    >
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :items="topics"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :search="search"
          :is-loading="isLoadingData"
          title="Topik Forum"
          subtitle="Daftar Topik yang ada di dalam forum"
          add-btn="Tambah Topik"
          @add-item="showFormAdd"
          @edit-item="edit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="topic.title === ''"
      :header="dialog === 'add' ? 'Tambah Topik': 'Edit Topik'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="topic.title"
            label="Topik"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="isDisabledButton"
      header="Hapus Topik"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiArrowLeft, mdiChevronDown, mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'Topic',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      topic: {
        title: '',
        school_uuid: null,
      },
      filterTopic: '',
      school: null,
      school_uuid: null,
      user_uuid: null,
      search: '',
      page: 1,
      totalItems: 10,
      totalPages: 0,
      dialog: null,
      width: 600,
      modalDialog: false,
      confirmDialog: false,
      imageOverlay: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isDisabledButton: false,
      overlay: false,
      zIndex: 10,
      icons: {
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiArrowLeft,
        mdiChevronDown,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Topik', value: 'title' },
        { text: 'Actions', value: 'actions' },
      ],
      topics: [],
    }
  },
  watch: {
    page: {
      handler() {
        this.listCategories()
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.getSchoolUuid()
    await this.listCategories()
    this.isLoadingTable = false
  },
  methods: {
    resetForm() {
      this.topic.title = ''
    },
    async add() {
      this.isLoadingButton = true
      this.topic.school_uuid = this.school_uuid
      await this.$services.ApiServices.add('forumtopic', this.topic).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listCategories()
    },
    async listCategories() {
      this.isLoadingData = true
      await this.$services.ApiServices.list('forumtopic', {
        school_uuid: this.school_uuid,
        page: this.page,
        search: this.filterTopic,
      }).then(
        async ({ data }) => {
          this.topics = await data.data.map((topic, index) => ({
            ...topic,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },

    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
      this.isDisabledButton = false
    },
    async edit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get('forumtopic', uuid).then(
        async ({ data }) => {
          this.topic = data.data
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },
    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update('forumtopic', this.topic, this.topic.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listCategories()
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('forumtopic', this.topic.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listCategories()
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async searchHandler(data) {
      this.filterTopic = data
      this.page = 1
      await this.listCategories()
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.topic.uuid = uuid
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0 !important;
}
</style>

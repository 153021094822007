<template>
  <div>
    <div class="title mb-4">
      <span class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        Forum
      </span>
      <small>Forum</small>
    </div>
    <div>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
        class="mt-6"
      >
        <v-tab
          v-for="(tabItem,index) in tabs"
          :key="tabItem.title"
          @click="reRenderComponent(index)"
        >
          <v-icon
            class="me-2"
            size="20"
          >
            {{ tabItem.icon }}
          </v-icon>
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        elevation="0"
        class="pt-8"
      >
        <v-tab-item>
          <forum-item :key="componentKey"></forum-item>
        </v-tab-item>
        <v-tab-item>
          <bookmark-item :key="componentKey2"></bookmark-item>
        </v-tab-item>
        <v-tab-item v-if="isModerator">
          <mute-suspend-item :key="componentKey3"></mute-suspend-item>
        </v-tab-item>
        <v-tab-item v-if="isModerator">
          <topic-item :key="componentKey4"></topic-item>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import BookmarkItem from './bookmark/Bookmark.vue'
import ForumItem from './forum/Forum.vue'
import MuteSuspendItem from './mute-suspend/MuteSuspend.vue'
import TopicItem from './topic/Topic.vue'
// eslint-disable-next-line object-curly-newline
import { mdiAlertOutline, mdiBookmarkPlusOutline, mdiChatProcessingOutline, mdiPlusCircleOutline } from '@mdi/js'

export default {
  name: 'Forum',
  components: {
    ForumItem,
    BookmarkItem,
    MuteSuspendItem,
    TopicItem,
  },
  data() {
    return {
      icons: {
        mdiPlusCircleOutline,
        mdiBookmarkPlusOutline,
        mdiAlertOutline,
        mdiChatProcessingOutline,
      },
      school: {},
      user_uuid: null,
      school_uuid: null,
      isModerator: false,
      componentKey: 0,
      componentKey2: 0,
      componentKey3: 0,
      componentKey4: 0,
      tab: '',
      tabs: [
        { icon: mdiChatProcessingOutline, title: 'Forum' },
        { icon: mdiBookmarkPlusOutline, title: 'Bookmark' },
        { icon: mdiAlertOutline, title: 'Mute/Suspend' },
        { icon: mdiPlusCircleOutline, title: 'Topik' },
      ],
    }
  },
  mounted() {
    this.getSchoolUuid()
    this.checkModerator()
    this.isLoadingCard = false
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey2 += 1
          break
        case 2:
          this.componentKey3 += 1
          break
        case 3:
          this.componentKey4 += 1
          break
        default:
          break
      }
    },
    checkModerator() {
      if (!this.isModerator) {
        this.tabs = [
          { icon: mdiChatProcessingOutline, title: 'Forum' },
          { icon: mdiBookmarkPlusOutline, title: 'Bookmark' },
        ]
      }
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
          this.isModerator = el.is_moderator

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
        this.isModerator = el.is_moderator
      })

      return true
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  border-bottom: 1px solid rgba(94, 86, 105, 0.14) !important;
  box-shadow: none !important;
}

.v-tabs-items::v-deep,
.v-tabs::v-deep .v-tabs-bar {
  background: transparent !important;
}
</style>
